import {
    Button,
    Row,
    Table,
    Col,
    notification,
    Input,
    Spin,
    Alert,
    Select,
} from "antd";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { DeleteOutlined, LeftOutlined } from "@ant-design/icons";
import Apicall from "../../Helper/Apicall";
import { reload } from "../../Helper/helper";
import Loader from "../../Helper/Loader";
import NotificationHandler from "../../Helper/NotificationHandler";

const BetList = () => {
    const { jobId } = useParams();
    const [loading, setLoading] = useState(true);
    const [api, contextHolder] = notification.useNotification({ maxCount: 1 });

    const [massSelectRow, setMassSelectRow] = useState([]);
    const [deleteDisabled, setDeleteDisabled] = useState(false);
    const [templateOption, setTemplateOption] = useState([]);
    const [betData, setBetData] = useState([]);
    const [typeOption, setTypeOption] = useState([]);
    const [checkRowAdded, setCheckRowAdded] = useState(false);
    const navigate = useNavigate();
    const handleTemplateClick = async (record) => {
        if (record.new) {
            let response = await Apicall("post", "getAllTemplate");
            if (response.success === 1) {
                setTemplateOption(response.data);
            }
        } else {
            setTemplateOption([]);
        }
    };
    const handleTemplateChange = (value, label) => {
        let temArray = betData;
        let rowEdit = temArray[0];
        console.log(rowEdit);
        rowEdit.template = label.label;
        temArray[0] = rowEdit;
        setBetData(temArray);
    };

    const handleTypeClick = (record) => {
        if (record.new) {
            setTypeOption([
                {
                    label: "4d",
                    value: "4d",
                },
                {
                    label: "3d",
                    value: "3d",
                },
                {
                    label: "2d",
                    value: "2d",
                },
            ]);
        } else {
            setTypeOption([]);
        }
    };
    const handleTypeChange = (value, label) => {
        let temArray = betData;
        let rowEdit = temArray[0];
        rowEdit.type = value;
        temArray[0] = rowEdit;
        setBetData(temArray);
    };
    const handleAmountChange = (e, record) => {
        if (record.new) {
            setBetData((prevBetData) => {
                const updatedBetData = [...prevBetData]; // Create a copy of the previous state
                const rowEdit = updatedBetData[0];
                rowEdit.amount = e.target.value; // Update the url
                updatedBetData[0] = rowEdit;
                return updatedBetData; // Return the updated state
            });
        }
    };
    const handleBetNumberChange = (e, record) => {
        if (record.new) {
            setBetData((prevBetData) => {
                const updatedBetData = [...prevBetData]; // Create a copy of the previous state
                const rowEdit = updatedBetData[0];
                rowEdit.bet = e.target.value; // Update the url
                updatedBetData[0] = rowEdit;
                console.log(updatedBetData);
                return updatedBetData; // Return the updated state
            });
        }
    };
    const handleUrlChange = (e, record) => {
        console.log(e.target.value);
        if (record.new) {
            setBetData((prevBetData) => {
                const updatedBetData = [...prevBetData]; // Create a copy of the previous state
                const rowEdit = updatedBetData[0];
                rowEdit.url = e.target.value; // Update the url
                updatedBetData[0] = rowEdit;
                return updatedBetData; // Return the updated state
            });
        }
    };

    const [betListData, setBetListData] = useState({
        jobId: "",
        marketName: "",
        startDate: "",
        endDate: "",
    });

    const columns = [
        {
            title: "Sr No",
            dataIndex: "key",
            width: 50,
        },
        {
            title: "Type",
            dataIndex: "type",
            render: (_, record) => (
                <>
                    <Select
                        value={record.type}
                        options={typeOption}
                        onClick={() => {
                            handleTypeClick(record);
                        }}
                        onChange={handleTypeChange}
                        style={{ width: 70 }}
                    />
                </>
            ),
        },
        {
            title: "Group",
            dataIndex: "groupName",
            width: 75,
        },
        {
            title: "Sub GroupName",
            dataIndex: "subGroupName",
            width: 75,
        },
        {
            title: "Bet No",
            dataIndex: "bet",
            render: (dataIndex, record) => {
                return (
                    <>
                        <Input
                            type="Number"
                            value={record.bet}
                            onChange={(e) => {
                                handleBetNumberChange(e, record);
                            }}
                        />
                    </>
                );
            },
        },
        {
            title: "Amount",
            dataIndex: "amount",
            render: (dataIndex, record) => {
                return (
                    <>
                        <Input
                            type="Number"
                            value={record.amount}
                            max={9999}
                            min={1}
                            onChange={(e) => {
                                handleAmountChange(e, record);
                            }}
                        />
                    </>
                );
            },
        },
        {
            title: "Status",
            dataIndex: "status",
        },
        {
            title: "Url",
            dataIndex: "url",
            width: 150,
            render: (_, record) => {
                return (
                    <>
                        <Input
                            value={record.url}
                            max={9999}
                            min={1}
                            onChange={(e) => {
                                handleUrlChange(e, record);
                            }}
                        />
                    </>
                );
            },
        },
        {
            title: "Serial No",
            dataIndex: "deviceId",
        },
        {
            title: "Template",
            dataIndex: "template",
            width: 200,
            render: (_, record) => {
                return (
                    <>
                        <Select
                            value={record.template}
                            options={templateOption}
                            onClick={() => {
                                handleTemplateClick(record);
                            }}
                            onChange={handleTemplateChange}
                            style={{ width: 150 }}
                        />
                    </>
                );
            },
        },

        {
            title: "Action",
            render: (_, record) => {
                return (
                    <Button
                        type="primary"
                        onClick={() => onSingleDelete(record)}
                    >
                        <DeleteOutlined />
                    </Button>
                );
            },
        },
    ];
    const handleAdd = () => {
        if (checkRowAdded) return api.error({ message: "Row allredy Added" });
        const newData = {
            type: "",
            bet: "",
            groupName: betData[0].groupName || "",
            subGroupName: betData[0].groupName || "",
            amount: "",
            url: "",
            deviceId: betData[0].deviceId || "",
            template: "",
            new: true,
        };
        setBetData([newData, ...betData]);
        setCheckRowAdded(true);
    };

    const getBetData = async () => {
        try {
            let payload = {
                jobId: jobId,
            };
            const response = await Apicall("post", "getAllBets", payload);
            if (response.success === 0) {
                throw new Error(response.message);
            } else {
                const { jobId, marketName, startDate, endDate, bets } =
                    response.data[0];
                setBetData(bets);
                setBetListData({
                    jobId: jobId,
                    marketName: marketName,
                    startDate: startDate,
                    endDate: endDate,
                });
                setLoading(false);
            }
        } catch (error) {
            api.error({ message: error.message });
            setLoading(false);
            navigate("/newJob");
        }
    };

    const onSingleDelete = async (record) => {
        try {
            let payload = {
                jobId: jobId,
                betNo: record.betNo,
                action: "SINGLE-DELETE",
            };
            setLoading(true);
            let response = await Apicall("post", "manageBets", payload);
            if (response.success === 0) {
                throw new Error(response.message);
            } else {
                getBetData();
                api.info({ message: "Updated Data Successfully", duration: 1 });
            }
        } catch (error) {
            api.error({ message: error.message, duration: 1.5 });
            setLoading(false);
        }
    };

    const rowSelection = {
        onChange:(record,selectedRowKeys,selectedRows)=>{
            setMassSelectRow(selectedRowKeys)
        },
        onSelect: (record, selectedRowKeys, selectedRows) => {
            if (selectedRowKeys.length > 1) {
                setDeleteDisabled(true);
            }
            setMassSelectRow([...massSelectRow, record]);
        },
    };
    const manageMassUpdate = async (action) => {
        try {
            if (massSelectRow.length <= 0) {
                api.error({
                    message: "Please select rows for Update and Delete",
                    duration: 1,
                });
            } else {
                let payload = {};
                if (action === "MASS-UPDATE") {
                    payload["action"] = "MASS-UPDATE";
                } else {
                    payload["action"] = "MASS-DELETE";
                }
                payload["dataArray"] = massSelectRow;
                payload["jobId"] = jobId;

                let response = await Apicall("post", "manageBets", payload);

                if (response.success === 0) {
                    throw new Error(response.message);
                } else {
                    api.info({ message: `${action} successfull`, duration: 1 });
                    setMassSelectRow([]);
                    reload();
                }
            }
        } catch (error) {
            api.error({ message: error.message, duration: 1 });
            setLoading(false);
        }
    };

    const singleUpdate = async () => {
        let payload = {};
        payload["action"] = "SINGLE-UPDATE"; // as Per backend Requirments
        payload["dataArray"] = betData[0]; // SingleObject
        payload["jobId"] = jobId;
        let response = await Apicall("post", "manageBets", payload);
        if (response.success === 0) {
            api.error({ message: response.message });
        } else {
            managePublish();
        }
    };

    const managePublish = async () => {
        try {
            // first Update the bets
            let response = await Apicall("post", "updateJobStatus", {
                jobId: jobId,
                status: "PUBLISH",
            });
            if (response.success === 0) {
                api.error({ message: response.message });
            } else {
                navigate("/newJob");
            }
        } catch (error) {
            NotificationHandler.error(error.message);
        }
    };

    useEffect(() => {
        getBetData();
    }, []);

    return (
        <>
            {contextHolder}
            {loading ? (
                <Loader message={"Fetching Bet List"} />
            ) : (
                <div className="home_center">
                    <Row justify={"center"}>
                        <Col span={8}>
                            <h1>Market Name</h1>
                            <h2>{betListData.marketName}</h2>
                        </Col>
                        <Col span={8}>
                            <h1>From Date</h1>
                            <h2>{betListData.startDate}</h2>
                        </Col>
                        <Col span={8}>
                            <h1>To Date</h1>
                            <h2>{betListData.endDate}</h2>
                        </Col>
                    </Row>
                    <Row>
                        <Button
                            onClick={handleAdd}
                            type="primary"
                            style={{
                                marginBottom: 16,
                            }}
                        >
                            Add a row
                        </Button>
                    </Row>
                    <Table
                        rowSelection={rowSelection}
                        columns={columns}
                        dataSource={betData}
                        total={betData.length}
                        defaultPageSize={200}
                        defaultCurrent={1}
                        showTotal={(total) => `Total ${total} items`}
                        scroll={{
                            y: 400,
                        }}
                    />
                    <Row justify={"center"}>
                        <Col span={4}>
                            <Button
                                type="primary"
                                onClick={() => {
                                    window.history.back();
                                }}
                            >
                                <LeftOutlined />
                                Back
                            </Button>
                        </Col>
                        <Col span={4}>
                            <Button
                                type="primary"
                                onClick={() => manageMassUpdate("MASS-DELETE")}
                            >
                                Bulk Delete
                            </Button>
                        </Col>
                        <Col span={4}>
                            <Button
                                type="primary "
                                onClick={() =>
                                    checkRowAdded
                                        ? singleUpdate()
                                        : managePublish()
                                }
                            >
                                Publish
                            </Button>
                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
};

export default BetList;
