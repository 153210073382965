import React, { useEffect, useState } from "react";
import { Form, Select } from "antd";
import Apicall from "../../Helper/Apicall";
import NotificationHandler from "../../Helper/NotificationHandler";
import LevelControl from "../../Form/LevelControl";
import { useForm } from "antd/es/form/Form";
import { useNavigate } from "react-router-dom";

const LevelControlPage = () => {
    const navigate = useNavigate()
    const [form] = useForm();
    const [users, setUsers] = useState([]);
    const [userId, setUserId] = useState("");

    const [initialValues, setInitalValues] = useState({
        "groupSubGroupList": {
            "read": false,
            "write": false
        },
        "templateList": {
            "read": false,
            "write": false
        },
        "newUser": {
            "read": false,
            "write": false
        },
        "editUser": {
            "read": false,
            "write": false
        },
        "levelControl": {
            "read": false,
            "write": false
        },
        "addConfig": {
            "read": false,
            "write": false
        },
        "newJob": {
            "read": false,
            "write": false
        },
        "addNumber": {
            "read": false,
            "write": false
        }
    })
    const handelFormFinish = async (values) => {
        const response = await Apicall("post", "userLevelControl", {
            action: "update",
            userId: userId,
            values: values,
        });
        if (!response.success) {
            NotificationHandler.error(response.message);
        }
        NotificationHandler.success(response.message);
        navigate(-1)
    };
    const onClick = async () => {
        const response = await Apicall("post", "getAllUsers");
        if (!response.success) {
            return NotificationHandler.error(response.message);
        }
        setUsers(response.data);
    };
    const handleChange = async (value, label) => {
        setUserId(value);
        const response = await Apicall("post", "userLevelControl", {
            action: "get",
            userId: value,
        });
        if (!response.success) {
            return NotificationHandler.error(response.message);
        } else {
            let temObj = response.data.length > 0 ? response.data[0] : initialValues;
            form.setFieldsValue({
                groupSubGroupList: temObj?.groupSubGroupList,
                templateList: temObj?.templateList,
                addUser: temObj?.addUser,
                editUser: temObj?.editUser,
                levelControl: temObj?.levelControl,
                addConfig: temObj?.addConfig,
                newJob: temObj?.newJob,
                addNumber:temObj?.addNumber
            });
        }
    };
    useEffect(() => {}, [initialValues]);
    return (
        <>
            <Form
                name="leveForm"
                form={form}
                onFinish={handelFormFinish}
                style={{ margin: "100px", width: "50vw" }}
                initialValues={initialValues}
            >
                <Select
                    style={{ width: "25vw" }}
                    placeholder="Select User"
                    options={users}
                    onClick={onClick}
                    onChange={handleChange}
                />
                <LevelControl />
            </Form>
        </>
    );
};

export default LevelControlPage;
