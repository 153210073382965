import React, { useState } from 'react';
import {useNavigate} from "react-router-dom"
import *  as  Icons  from '@ant-design/icons';
import { Breadcrumb, Layout, Menu, theme } from 'antd';
import MenuItem from 'antd/es/menu/MenuItem';
const { Header, Content, Footer, Sider } = Layout;
function getItem(label, key, icon, children) {
  return {
    key,
    icon,
    children,
    label,
  };
}

let sidebar = JSON.parse(window.localStorage.getItem('sidebar'))
const convertNavbarToItem = (data) => {
    return data?.map((item) => {
        const temItem = {
            key: item.key,
            label: item.key,
        };
        if (item.children && item.children.length > 0) {
            temItem.children = convertNavbarToItem(item.children);
        }
        return item;
    });
};
// const items = [
//     getItem("Home", "/", <HomeOutlined />),
//     getItem("Master Group", "group", <GroupOutlined />, [
//         getItem("Group/SubGroup/Website", "/groupSubGroupList"),
//         getItem("Add Template", "/templateList"),
//     ]),
//     getItem("User Mangement", "user", <UserOutlined />, [
//         getItem("Add User", "/newUser"),
//         getItem("Edit User", "/editUser"),
//     ]),
//     // getItem("Site Managment", "site", <GlobalOutlined />, [
//     //     getItem("Add Website", "/addWebsite"),
//     //     getItem("Edit Website", "/editWebsite"),
//     // ]),
//     getItem("Bet Mangement", "bet", <MoneyCollectFilled />, [
//         getItem("Manage Config", "/addConfig"),
//         getItem("Manage Job", "/newJob"),
//     ]),
//     getItem("ADD NUMBER", "/addNumber", <NumberOutlined />),

// ];
const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [openKeys,setOpekKeys] = useState(window.location.pathname)
  const navigate = useNavigate()
  const onClick = (e)=>{
    navigate(e.key)
  }
  return (
      <Layout
          style={{
              minHeight: "100vh",
          }}
      >
          <Sider
              style={{
                overflow: 'auto',
                height: '100vh',
                position: 'fixed',
                left: 0,
                top: 0,
                bottom: 0,
                marginTop:"10vh",

              }}
              theme='light'
              width={250}
          >
              <Menu
                  defaultSelectedKeys={[window.location.pathname]}
                  mode="inline"
                  items={convertNavbarToItem(sidebar)}
                  onClick={onClick}
                  theme='light'
              />
              
          </Sider>
      </Layout>
  );
};
export default Sidebar;