import {
    Select,
    Row,
    Col,
    Input,
    notification,
    Button,
    Form,
    Space,
    Checkbox,
    InputNumber,
    Tooltip,
} from "antd";
import React, { useEffect, useState } from "react";
import {
    CaretLeftFilled,
    CaretLeftOutlined,
    CaretRightFilled,
    InfoCircleFilled,
} from "@ant-design/icons";
import Apicall from "../../Helper/Apicall";
import "../../App.css";
import { reload } from "../../Helper/helper";
import Loader from "../../Helper/Loader";
import { groupArray, configData } from "../../Data/GroupData";
import NotificationHandler from "../../Helper/NotificationHandler";
const CheckboxGroup = Checkbox.Group;

const AddConfig = () => {
    const [api, contextHolder] = notification.useNotification({ maxCount: 1 });
    const [loader, setLoader] = useState(false);
    const [fourDPatternOption, setFourDPatternOption] = useState([]);
    const [fourDResultOption, setFourDResultOption] = useState([]);
    const [fourDPositionOption, setFourDPostionOption] = useState(configData.position["4d"]);
    const [threeDPatternOption, setThreeDPatternOption] = useState([]);
    const [threeDResultOption, setThreeDResultOption] = useState([]);
    const [threeDPositionOption, setThreeDPostionOption] = useState(configData.position['3d']);
    const [twoDPatternOption, setTwoDPatternOption] = useState([]);
    const [twoDResultOption, setTwoDResultOption] = useState([]);
    const [twoDPositionOption, setTwoDPostionOption] = useState(configData.position['2d']);
    const [openPositionFor4d, setOpenPostion4d] = useState([]);
    const [openPositionFor3d, setOpenPostion3d] = useState([]);
    const [openPositionFor2d, setOpenPostion2d] = useState([]);
    const [selected4dPosition, setSelected4dPosition] = useState([]);
    const [selected3dPosition, setSelected3dPosition] = useState([]);
    const [selected2dPosition, setSelected2dPosition] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const [addConfig, setAddConfig] = useState({
        configId: "",
        url: "",
        groupName: "",
        pattern: {
            "4d": [],

            "3d": [],

            "2d": [],
        },
        result: {
            "4d": "",
            "3d": "",
            "2d": "",
        },
        position: {
            "4d": [],
            "3d": [],
            "2d": [],
        },

        // fourDBetsCount: "",
        // threeDBetsCount: "",
        // twoDBetsount: "",
        // fourDRangeMin: "",
        // fourDRangeMax: "",
        // threeDRangeMin: "",
        // threeDRangeMax: "",
        // twoDRangeMin: "",
        // twoDRangeMax: "",
        fourDMulNominal: "",
        fourDTarget: "",
        threeDMulNominal: "",
        threeDTarget: "",
        twoDMulNominal: "",
        twoDTarget: "",
        // fourDMulX: "",
        // threeDMulX: "",
        // twoDMulX: "",
    });

    const [groupData, setGroupData] = useState([]);

    const selectGroup = async (value, label) => {
        getConfig(value, label);
        // setAddConfig({ ...addConfig, groupName: label.label });
    };

    useEffect(() => {}, [
        fourDPositionOption,
        threeDPositionOption,
        twoDPositionOption,
    ]);
    const updateNewConfig = async () => {
        // will create new Object if there is no Object Found
        try {
            if (
                !addConfig.url ||
                !addConfig.fourDTarget ||
                !addConfig.fourDMulNominal ||
                !addConfig.threeDTarget ||
                !addConfig.threeDMulNominal ||
                !addConfig.twoDMulNominal ||
                !addConfig.twoDTarget
            ) {
                throw new Error("Please fill all value");
            } else {
                setLoader(true); // Loader
                let response = await Apicall("post", "updateConfig", addConfig);

                if (response && response.success === 0) {
                    throw new Error(response.message);
                } else {
                    api.info({ message: "Config updated successfully" });
                    let label = {label:addConfig.groupName}
                    getConfig("",label)
                }
            }
        } catch (error) {
            api.error({ message: error.message });
            setLoader(false);
        }finally{
            setLoader(false)
        }
    };
    const getConfig = async (value, label) => {
        setLoader(true)
        try {
            let response = await Apicall("post", "getConfigList", {
                groupName: label.label,
            });
            if (response.success === 0) {
                api.error({ message: response.message, duration: 1.5 });
                setAddConfig({
                    pattern: "",
                    result: "",
                    groupName: label.label,
                    position:"",
                    fourDBetsCount: "",
                    threeDBetsCount: "",
                    twoDBetsount: "",
                    fourDRangeMin: "",
                    fourDRangeMax: "",
                    threeDRangeMin: "",
                    threeDRangeMax: "",
                    twoDRangeMin: "",
                    twoDRangeMax: "",
                    fourDMulNominal: "",
                    fourDTarget: "",
                    threeDMulNominal: "",
                    threeDTarget: "",
                    twoDMulNominal: "",
                    twoDTarget: "",
                    fourDMulX: "",
                    threeDMulX: "",
                    twoDMulX: "",
                });
                setFourDPostionOption(configData.position["4d"]);
                setThreeDPostionOption(configData.position["3d"]);
                setTwoDPostionOption(configData.position['2d'])
            
            } else {
                let data = response.data[0];
                setAddConfig({
                    configId: data.configId,
                    groupName: data.groupName,
                    url: data.url,
                    pattern: data.pattern,
                    result: data.result,
                    position: data.position,
                    fourDRangeMin: data.fourDRangeMin,
                    fourDRangeMax: data.fourDRangeMax,
                    threeDRangeMin: data.threeDRangeMin,
                    threeDRangeMax: data.threeDRangeMax,
                    twoDRangeMin: data.twoDRangeMin,
                    twoDRangeMax: data.twoDRangeMax,
                    fourDTarget: data.fourDTarget,
                    fourDMulNominal: data.fourDMulNominal,
                    threeDTarget: data.threeDTarget,
                    threeDMulNominal: data.threeDMulNominal,
                    twoDTarget: data.twoDTarget,
                    twoDMulNominal: data.twoDMulNominal,
                    fourDMulX: data.fourDMulX,
                    threeDMulX: data.threeDMulX,
                    twoDMulX: data.twoDMulX,
                });
                // setFourDPostionOption(data.position["4d"]);
                // setThreeDPostionOption(data.position["3d"]);
                // setTwoDPostionOption(data.position["2d"]);
            }
        } catch (error) {
            console.error(error, "-----");
            NotificationHandler.error(error.message);
        } finally {
            setLoader(false);
        }
    };
    const getSubgroupData = async () => {
        // call the APi for Data
        let data = await Apicall("post", "getAllGroups", {
            action: "groupData",
        });
        const tem = {
            value: null,
            label: "FOR-ALL",
        };
        data.data.push(tem);
        setGroupData(data.data);
    };
    const onBetPatternClick = async () => {
        // call api to set the Data here
        setFourDPatternOption(configData.pattern["4d"]);
        setThreeDPatternOption(configData.pattern["3d"]);
        setTwoDPatternOption(configData.pattern["2d"]);
    };
    const onBetResultChange = async () => {
        //call Api to set
        setFourDResultOption(configData.result["4d"]);
        setThreeDResultOption(configData.result["3d"]);
        setTwoDResultOption(configData.result["2d"]);
    };
    const onBetPostionClick = async () => {
        setFourDPostionOption(configData.position["4d"]);
        fourDPositionOption &&
            fourDPositionOption.map((item1) => {
                addConfig.position["4d"] &&
                    addConfig.position["4d"].map((item2) => {
                        if (item1.value === item2.value) {
                            item1.count = item2.count;
                            item1.label = item1.label;
                            item1.value = item1.value;
                            item1.checked = true;
                        }
                    });
            });
        setThreeDPostionOption(configData.position["3d"]);
        threeDPositionOption &&
            threeDPositionOption.map((item1) => {
                addConfig.position["3d"] &&
                    addConfig.position["3d"].map((item2) => {
                        if (item1.value === item2.value) {
                            item1.count = item2.count;
                            item1.label = item1.label;
                            item1.value = item1.value;
                            item1.checked = true;
                        }
                    });
            });
        setTwoDPostionOption(configData.position["2d"]);
        twoDPositionOption &&
            twoDPositionOption.map((item1) => {
                addConfig.position["2d"] &&
                    addConfig.position["2d"].map((item2) => {
                        if (item1.value === item2.value) {
                            item1.count = item2.count;
                            item1.label = item1.label;
                            item1.value = item1.value;
                            item1.checked = true;
                        }
                    });
            });
    };
    // on selection the 4d pattern
    const on4dBetPatternChange = (value, label) => {
        const updatedPattern = { ...addConfig.pattern, "4d": label };
        setAddConfig((prevState) => ({
            ...prevState,
            pattern: updatedPattern,
        }));
    };
    const on3dBetPatternChange = (value, label) => {
        const updatedPattern = { ...addConfig.pattern, "3d": label };
        setAddConfig((prevState) => ({
            ...prevState,
            pattern: updatedPattern,
        }));
    };
    const on2dBetPatternChange = (value, label) => {
        const updatedPattern = { ...addConfig.pattern, "2d": label };
        setAddConfig((prevState) => ({
            ...prevState,
            pattern: updatedPattern,
        }));
    };

    // on Selection of 4d Result ;
    const on4dResultChange = (value, label) => {
        setAddConfig({
            ...addConfig,
            result: { ...addConfig.result, "4d": label },
        });
    };
    const on3dResultChange = (value, label) => {
        setAddConfig({
            ...addConfig,
            result: { ...addConfig.result, "3d": label },
        });
    };
    const on2dResultChange = (value, label) => {
        setAddConfig({
            ...addConfig,
            result: { ...addConfig.result, "2d": label },
        });
    };

    const onBetPostionSelect = async () => {};

    const onFourDCheckBoxClick = (e, clickedItem) => {
        let temArray = fourDPositionOption;
        temArray.map((item) => {
            if (item.value == clickedItem.value) {
                item.checked = e.target.checked;
            }
        });

        setFourDPostionOption(temArray);
        const filteredOption = temArray.filter((item) => item.checked == true);
        setAddConfig({
            ...addConfig,
            position: { ...addConfig.position, "4d": filteredOption },
        });
    };

    const onThreeDCheckBoxClick = (e, clickedItem) => {
        let temArray = threeDPositionOption;
        temArray.map((item) => {
            if (item.value == clickedItem.value) {
                item.checked = e.target.checked;
            }
        });

        setThreeDPostionOption(temArray);
        const filteredOption = temArray.filter((item) => item.checked == true);
        setAddConfig({
            ...addConfig,
            position: { ...addConfig.position, "3d": filteredOption },
        });
    };

    const onTwoDCheckBoxClick = (e, clickedItem) => {
        let temArray = twoDPositionOption;
        temArray.map((item) => {
            if (item.value == clickedItem.value) {
                item.checked = e.target.checked;
            }
        });

        setTwoDPostionOption(temArray);
        const filteredOption = temArray.filter((item) => item.checked == true);
        setAddConfig({
            ...addConfig,
            position: { ...addConfig.position, "2d": filteredOption },
        });
    };

    const on4dInputChange = (e, clickedItem) => {
        let temArray = fourDPositionOption;
        temArray.map((item) => {
            if (item.value === clickedItem.value) {
                item.count = e;
            }
        });

        setFourDPostionOption(temArray);
        const filteredOption = temArray.filter((item) => item.checked == true);
        setAddConfig({
            ...addConfig,
            position: { ...addConfig.position, "4d": filteredOption },
        });
    };

    const on3dInputChange = (e, clickedItem) => {
        let temArray = threeDPositionOption;
        temArray.map((item) => {
            if (item.value === clickedItem.value) {
                item.count = e;
            }
        });

        setFourDPostionOption(temArray);
        const filteredOption = temArray.filter((item) => item.checked == true);
        setAddConfig({
            ...addConfig,
            position: { ...addConfig.position, "3d": filteredOption },
        });
    };

    const on2dInputChange = (e, clickedItem) => {
        let temArray = twoDPositionOption;
        temArray.map((item) => {
            if (item.value === clickedItem.value) {
                item.count = e;
            }
        });

        setTwoDPostionOption(temArray);
        const filteredOption = temArray.filter((item) => item.checked == true);
        setAddConfig({
            ...addConfig,
            position: { ...addConfig.position, "2d": filteredOption },
        });
    };

    const handlePostionClear =(value)=>{
        console.log(value);
    }

    useEffect(() => {}, []);

    useEffect(() => {}, [
        fourDPositionOption,
        threeDPositionOption,
        twoDPositionOption,
        addConfig,
    ]);
    return (
        <div style={{ height: "100vh" }}>
            {contextHolder}

            {!loader &&
            fourDPositionOption.length > 0 &&
            threeDPositionOption.length > 0 &&
            twoDPositionOption.length > 0 ? (
                <div className="home_center">
                    <Row>
                        <h2>Bet Configuration</h2>
                    </Row>
                    <Row className="input">
                        <Form.Item label="Select Group">
                            <Select
                                className="input"
                                value={addConfig.groupName}
                                onChange={selectGroup}
                                onClick={getSubgroupData} // make api call to fetch data of Group
                                style={{
                                    width: 1000,
                                }}
                                options={groupData}
                            />
                        </Form.Item>
                    </Row>
                    <Row className="input">
                        Result Url
                        <Input
                            placeholder="Add URL"
                            value={addConfig.url}
                            onChange={(e) => {
                                setAddConfig({
                                    ...addConfig,
                                    url: e.target.value,
                                });
                            }}
                        />
                    </Row>
                    <Row>Exclude bet Pattern</Row>
                    <Row>
                        <Col className="input" span={8}>
                            <Col span={8}>4D Pattern</Col>
                            <Col span={8}>
                                <Select
                                    style={{
                                        width: "200%",
                                    }}
                                    className="input"
                                    mode="multiple"
                                    allowClear
                                    value={addConfig.pattern["4d"]}
                                    placeholder={"Please Select"}
                                    onClick={onBetPatternClick}
                                    onChange={on4dBetPatternChange}
                                    options={fourDPatternOption}
                                />
                            </Col>
                        </Col>
                        <Col className="input" span={8}>
                            <Col span={8}>3D Pattern</Col>
                            <Col span={8}>
                                <Select
                                    className="input"
                                    style={{
                                        width: "200%",
                                    }}
                                    mode="multiple"
                                    value={addConfig.pattern["3d"]}
                                    placeholder={"Please Select"}
                                    onChange={on3dBetPatternChange}
                                    onClick={onBetPatternClick}
                                    options={threeDPatternOption}
                                />
                            </Col>
                        </Col>
                        <Col className="input" span={8}>
                            <Col span={8}>2D Pattern</Col>
                            <Col span={8}>
                                <Select
                                    className="input"
                                    style={{
                                        width: "200%",
                                    }}
                                    mode="multiple"
                                    value={addConfig.pattern["2d"]}
                                    placeholder={"Please Select"}
                                    onClick={onBetPatternClick}
                                    onChange={on2dBetPatternChange}
                                    options={twoDPatternOption}
                                />
                            </Col>
                        </Col>
                    </Row>
                    <Row>Exclude bet Result</Row>
                    <Row>
                        <Col className="input" span={8}>
                            <Col span={8}>4D Result</Col>
                            <Col span={8}>
                                <Select
                                    style={{
                                        width: "200%",
                                    }}
                                    className="input"
                                    value={addConfig.result["4d"]}
                                    placeholder={"Please Select"}
                                    onChange={on4dResultChange}
                                    onClick={onBetResultChange}
                                    options={fourDResultOption}
                                />
                            </Col>
                        </Col>
                        <Col className="input" span={8}>
                            <Col span={8}>3D Result</Col>
                            <Col span={8}>
                                <Select
                                    className="input"
                                    style={{
                                        width: "200%",
                                    }}
                                    placeholder={"Please Select"}
                                    value={addConfig.result["3d"]}
                                    onClick={onBetResultChange}
                                    onChange={on3dResultChange}
                                    options={threeDResultOption}
                                />
                            </Col>
                        </Col>
                        <Col className="input" span={8}>
                            <Col span={8}>2D Result</Col>
                            <Col span={8}>
                                <Select
                                    className="input"
                                    style={{
                                        width: "200%",
                                    }}
                                    placeholder={"Please Select"}
                                    value={addConfig.result["2d"]}
                                    onClick={onBetResultChange}
                                    onChange={on2dResultChange}
                                    options={twoDResultOption}
                                />
                            </Col>
                        </Col>
                    </Row>
                    <Row>Filter By Postion</Row>
                    <Row>
                        <Col className="input" span={8}>
                            <Col span={8}>4D Postion</Col>
                            <Col span={8}>
                                <Select
                                    style={{
                                        width: "150%",
                                    }}
                                    className="input"
                                    loading={isLoading}
                                    onClear={handlePostionClear}
                                    mode="multiple"
                                    value={addConfig.position["4d"]}
                                    dropdownRender={(menu) => (
                                        <>
                                            <Tooltip title="Enter values like 16345">
                                                <span>
                                                    <InfoCircleFilled />
                                                </span>
                                            </Tooltip>
                                            {fourDPositionOption
                                                ? fourDPositionOption.map(
                                                      (item) => {
                                                          return (
                                                              <>
                                                                  <div
                                                                      style={{
                                                                          fontSize:
                                                                              "0.9rem",
                                                                      }}
                                                                      width={
                                                                          500
                                                                      }
                                                                      key={
                                                                          item.value
                                                                      }
                                                                  >
                                                                      <div>
                                                                          {
                                                                              item.label
                                                                          }{" "}
                                                                          <Checkbox
                                                                              checked={
                                                                                  item.checked
                                                                              }
                                                                              onChange={(
                                                                                  e,
                                                                                  value = item
                                                                              ) =>
                                                                                  onFourDCheckBoxClick(
                                                                                      e,
                                                                                      value
                                                                                  )
                                                                              }
                                                                          />
                                                                          {item.checked ? (
                                                                              <>
                                                                                  <InputNumber
                                                                                      value={
                                                                                          item.count
                                                                                      }
                                                                                      style={{
                                                                                          width: "100%",
                                                                                      }}
                                                                                      maxLength={
                                                                                          9
                                                                                      }
                                                                                      onChange={(
                                                                                          e,
                                                                                          value = item
                                                                                      ) => {
                                                                                          on4dInputChange(
                                                                                              e,
                                                                                              value
                                                                                          );
                                                                                      }}
                                                                                  />
                                                                              </>
                                                                          ) : (
                                                                              ""
                                                                          )}
                                                                          {/* <Input
                                                                    name={
                                                                        item.value
                                                                    }
                                                                    width={200}
                                                                    max={8}
                                                                    min={1}
                                             
                                                                />{" "}
                                                                Result */}
                                                                      </div>
                                                                  </div>
                                                              </>
                                                          );
                                                      }
                                                  )
                                                : ""}
                                        </>
                                    )}
                                    placeholder={"Please Select"}
                                    onClick={onBetPostionClick}
                                    options={fourDPositionOption}
                                />
                            </Col>
                        </Col>
                        <Col className="input" span={8}>
                            <Col span={8}>3D Postion</Col>
                            <Col span={8}>
                                <Select
                                    className="input"
                                    style={{
                                        width: "150%",
                                    }}
                                    mode="multiple"
                                    loading={isLoading}
                                    placeholder={"Please Select"}
                                    value={addConfig.position["3d"]}
                                    dropdownRender={(menu) => (
                                        <>
                                            <Tooltip title="Enter values like 16345">
                                                <span>
                                                    <InfoCircleFilled />
                                                </span>
                                            </Tooltip>
                                            {threeDPositionOption
                                                ? threeDPositionOption.map(
                                                      (item) => {
                                                          return (
                                                              <>
                                                                  <div
                                                                      style={{
                                                                          fontSize:
                                                                              "0.9rem",
                                                                      }}
                                                                      width={
                                                                          500
                                                                      }
                                                                      key={
                                                                          item.value
                                                                      }
                                                                  >
                                                                      <div>
                                                                          {
                                                                              item.label
                                                                          }{" "}
                                                                          <Checkbox
                                                                              checked={
                                                                                  item.checked
                                                                              }
                                                                              onChange={(
                                                                                  e,
                                                                                  value = item
                                                                              ) =>
                                                                                  onThreeDCheckBoxClick(
                                                                                      e,
                                                                                      value
                                                                                  )
                                                                              }
                                                                          />
                                                                          {item.checked ? (
                                                                              <>
                                                                                  <InputNumber
                                                                                      style={{
                                                                                          width: "100%",
                                                                                      }}
                                                                                      value={
                                                                                          item.count
                                                                                      }
                                                                                      maxLength={
                                                                                          9
                                                                                      }
                                                                                      onChange={(
                                                                                          e,
                                                                                          value = item
                                                                                      ) => {
                                                                                          on3dInputChange(
                                                                                              e,
                                                                                              value
                                                                                          );
                                                                                      }}
                                                                                  />
                                                                              </>
                                                                          ) : (
                                                                              ""
                                                                          )}
                                                                      </div>
                                                                  </div>
                                                              </>
                                                          );
                                                      }
                                                  )
                                                : ""}
                                        </>
                                    )}
                                    onChange={onThreeDCheckBoxClick}
                                    onClick={onBetPostionSelect}
                                    options={threeDPositionOption}
                                />
                            </Col>
                        </Col>
                        <Col className="input" span={8}>
                            <Col span={8}>2D Postion</Col>
                            <Col span={8}>
                                <Select
                                    className="input"
                                    style={{
                                        width: "150%",
                                    }}
                                    loading={isLoading}
                                    value={addConfig.position["2d"]}
                                    dropdownRender={(menu) => (
                                        <>
                                            <Tooltip title="Enter values like 16345">
                                                <span>
                                                    <InfoCircleFilled />
                                                </span>
                                            </Tooltip>
                                            {twoDPositionOption
                                                ? twoDPositionOption.map(
                                                      (item) => {
                                                          return (
                                                              <>
                                                                  <div
                                                                      style={{
                                                                          fontSize:
                                                                              "0.9rem",
                                                                      }}
                                                                      width={
                                                                          500
                                                                      }
                                                                      key={
                                                                          item.value
                                                                      }
                                                                  >
                                                                      <div>
                                                                          {
                                                                              item.label
                                                                          }{" "}
                                                                          <Checkbox
                                                                              checked={
                                                                                  item.checked
                                                                              }
                                                                              onChange={(
                                                                                  e,
                                                                                  value = item
                                                                              ) =>
                                                                                  onTwoDCheckBoxClick(
                                                                                      e,
                                                                                      value
                                                                                  )
                                                                              }
                                                                          />
                                                                          {item.checked ? (
                                                                              <>
                                                                                  <InputNumber
                                                                                      style={{
                                                                                          width: "100%",
                                                                                      }}
                                                                                      value={
                                                                                          item.count
                                                                                      }
                                                                                      maxLength={
                                                                                          9
                                                                                      }
                                                                                      onChange={(
                                                                                          e,
                                                                                          value = item
                                                                                      ) => {
                                                                                          on2dInputChange(
                                                                                              e,
                                                                                              value
                                                                                          );
                                                                                      }}
                                                                                  />
                                                                              </>
                                                                          ) : (
                                                                              ""
                                                                          )}
                                                                      </div>
                                                                  </div>
                                                              </>
                                                          );
                                                      }
                                                  )
                                                : ""}
                                        </>
                                    )}
                                    mode="multiple"
                                    placeholder={"Please Select"}
                                    onClick={onBetPostionClick}
                                    onChange={onTwoDCheckBoxClick}
                                    options={twoDPositionOption}
                                />
                            </Col>
                        </Col>
                    </Row>

                    <div className="config_betRegionRight">
                        <Row justify={"start"}>
                            <h4>Multipliers</h4>
                        </Row>
                        <Row justify={"start"}>
                            <Col span={10}>Target</Col>
                            <Col span={8}>Multiplier Nominal</Col>
                        </Row>
                        <Row align={"middle"}>
                            <Col span={4}>4D</Col>
                            <Col span={6}>
                                <Input
                                    type="Number"
                                    value={addConfig.fourDTarget}
                                    onChange={(e) => {
                                        setAddConfig({
                                            ...addConfig,
                                            fourDTarget: Number(e.target.value),
                                        });
                                    }}
                                />
                            </Col>
                            <Col span={6}>
                                <Input
                                    type="Number"
                                    value={addConfig.fourDMulNominal}
                                    onChange={(e) => {
                                        setAddConfig({
                                            ...addConfig,
                                            fourDMulNominal: Number(
                                                e.target.value
                                            ),
                                        });
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row align={"middle"}>
                            <Col span={4}>3D</Col>
                            <Col span={6}>
                                <Input
                                    type="Number"
                                    value={addConfig.threeDTarget}
                                    onChange={(e) => {
                                        setAddConfig({
                                            ...addConfig,
                                            threeDTarget: Number(
                                                e.target.value
                                            ),
                                        });
                                    }}
                                />
                            </Col>
                            <Col span={6}>
                                <Input
                                    type="Number"
                                    value={addConfig.threeDMulNominal}
                                    onChange={(e) => {
                                        setAddConfig({
                                            ...addConfig,
                                            threeDMulNominal: Number(
                                                e.target.value
                                            ),
                                        });
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row align={"middle"}>
                            <Col span={4}>2D</Col>
                            <Col span={6}>
                                <Input
                                    type="Number"
                                    value={addConfig.twoDTarget}
                                    onChange={(e) => {
                                        setAddConfig({
                                            ...addConfig,
                                            twoDTarget: Number(e.target.value),
                                        });
                                    }}
                                />
                            </Col>
                            <Col span={6}>
                                <Input
                                    type="Number"
                                    value={addConfig.twoDMulNominal}
                                    onChange={(e) => {
                                        setAddConfig({
                                            ...addConfig,
                                            twoDMulNominal: Number(
                                                e.target.value
                                            ),
                                        });
                                    }}
                                />
                            </Col>
                        </Row>
                    </div>
                    <Row justify={"center"} style={{ marginTop: "10px" }}>
                        <Button type="primary" onClick={updateNewConfig}>
                            Update Config
                        </Button>
                    </Row>
                </div>
            ) : (
                <Loader message={"Getting Config Data"} />
            )}
        </div>
    );
};

export default AddConfig;
