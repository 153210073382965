import { Button, Checkbox, Form, Row } from "antd";
import React from "react";
import { Col } from "antd";
import { useNavigate } from "react-router-dom";
import { useForm } from "antd/es/form/Form";

const LevelControl = () => {
    const navigate = useNavigate();
    return (
        <>
            <Row>
                <Col span={8}>
                    <h3>PERMISSIONS</h3>
                </Col>
                <Col span={8}>
                    <h3>EDIT</h3>
                </Col>
                <Col span={8}>
                    <h3>VIEW</h3>
                </Col>
            </Row>
            <Row align={"middle"}>
                <Col span={8}>
                    <h3>Group/SubGroup/Website</h3>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name={["groupSubGroupList", "read"]}
                        valuePropName="checked"
                    >
                        <Checkbox>Read</Checkbox>
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        name={["groupSubGroupList", "write"]}
                        valuePropName="checked"
                    >
                        <Checkbox>Write</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <Row align={"middle"}>
                <Col span={8}>
                    <h3>Template List</h3>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name={["templateList", "read"]}
                        valuePropName="checked"
                    >
                        <Checkbox>Read</Checkbox>
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        name={["templateList", "write"]}
                        valuePropName="checked"
                    >
                        <Checkbox>Write</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <Row align={"middle"}>
                <Col span={8}>
                    <h3>Add User</h3>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name={["newUser", "read"]}
                        valuePropName="checked"
                    >
                        <Checkbox>Read</Checkbox>
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        name={["newUser", "write"]}
                        valuePropName="checked"
                    >
                        <Checkbox>Write</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <Row align={"middle"}>
                <Col span={8}>
                    <h3>Edit User</h3>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name={["editUser", "read"]}
                        valuePropName="checked"
                    >
                        <Checkbox>Read</Checkbox>
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        name={["editUser", "write"]}
                        valuePropName="checked"
                    >
                        <Checkbox>Write</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <Row align={"middle"}>
                <Col span={8}>
                    <h3>Level Control</h3>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name={["levelControl", "read"]}
                        valuePropName="checked"
                    >
                        <Checkbox>Read</Checkbox>
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        name={["levelControl", "write"]}
                        valuePropName="checked"
                    >
                        <Checkbox>Write</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <Row align={"middle"}>
                <Col span={8}>
                    <h3>Manage Config</h3>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name={["addConfig", "read"]}
                        valuePropName="checked"
                    >
                        <Checkbox>Read</Checkbox>
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        name={["addConfig", "write"]}
                        valuePropName="checked"
                    >
                        <Checkbox>Write</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <Row align={"middle"}>
                <Col span={8}>
                    <h3>Manage Job</h3>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name={["newJob", "read"]}
                        valuePropName="checked"
                    >
                        <Checkbox>Read</Checkbox>
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        name={["newJob", "write"]}
                        valuePropName="checked"
                    >
                        <Checkbox>Write</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <Row align={"middle"}>
                <Col span={8}>
                    <h3>Add Number</h3>
                </Col>
                <Col span={8}>
                    <Form.Item
                        name={["addNumber", "read"]}
                        valuePropName="checked"
                    >
                        <Checkbox>Read</Checkbox>
                    </Form.Item>
                </Col>

                <Col span={8}>
                    <Form.Item
                        name={["addNumber", "write"]}
                        valuePropName="checked"
                    >
                        <Checkbox>Write</Checkbox>
                    </Form.Item>
                </Col>
            </Row>
            <Row style={{ margin: "50px" }}>
                <Col span={12}>
                    <Button htmlType="submit" type="primary">
                        Save
                    </Button>
                </Col>

                <Button onClick={() => navigate(-1)}>Back</Button>
            </Row>
        </>
    );
};

export default LevelControl;

let level = {
    groupSubGroupList: { read: true, write: true },
    templateList: { read: true, write: true },
};
