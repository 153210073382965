import { Button, Row, Table, Col, Spin, Alert, notification } from "antd";
import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { DeleteOutlined, LeftOutlined } from "@ant-design/icons";
import Apicall from "../../Helper/Apicall";
import NotificationHandler from "../../Helper/NotificationHandler";

const BetHistory = () => {
    const { jobId } = useParams();
    const [loading, setLoading] = useState(false);
    const [betData, setBetData] = useState([]);
    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();
    const [betListData, setBetListData] = useState({
        jobId: "",
        marketName: "",
        startDate: "",
        endDate: "",
    });
    const getBetData = async () => {
      try {
        let payload = {
            jobId: jobId,
        };

        const response = await Apicall("post", "getAllBets", payload);
        if (response.success === 0) {
            setLoading(false);
            api.error({ message: response.message });
            setTimeout(() => {
                navigate("/newJob");
            }, 1000);
        } else {
            const { jobId, marketName, startDate, endDate, bets } =
                response.data[0];
            setBetData(bets);
            setLoading(true);
            setBetListData({
                jobId: jobId,
                marketName: marketName,
                startDate: startDate,
                endDate: endDate,
            });
        }
      } catch (error) {
        NotificationHandler.error(error.message)
      }
    };

    const columns = [
        {
            title: "Sr No",
            dataIndex: "key",
        },
        {
            title: "Type",
            dataIndex: "type",
        },
        {
            title: "Bet No",
            dataIndex: "bet",
        },
        {
            title: "Amount",
            dataIndex: "amount",
        },
        {
            title: "Status",
            dataIndex: "status",
        },
        {
            title: "Url",
            dataIndex: "url",
            width: 150,
        },
        {
            title: "Serial No",
            dataIndex: "serialNo",
        },
        {
            title: "Template",
            dataIndex: "template",
        },
    ];

    useEffect(() => {
        getBetData();
    },[]);
    return (
        <>
            {loading ? (
                <div className="home_center">
                    <Row justify={"center"}>
                        <Col span={8}>
                            <h1>Market Name</h1>
                            <h2>{betListData.marketName}</h2>
                        </Col>
                        <Col span={8}>
                            <h1>From Date</h1>
                            <h2>{betListData.startDate}</h2>
                        </Col>
                        <Col span={8}>
                            <h1>End Date</h1>
                            <h2>{betListData.endDate}</h2>
                        </Col>
                    </Row>
                    <Table
                        columns={columns}
                        dataSource={betData}
                        scroll={{
                            y: 400,
                        }}
                    />
                    <Row justify={"center"}>
                        <Col span={4}>
                            <Button
                                type="primary"
                                onClick={() => {
                                    window.history.back();
                                }}
                            >
                                <LeftOutlined />
                                Back
                            </Button>
                        </Col>
                    </Row>
                </div>
            ) : (
                <div className="home_center">
                    <Spin
                        contentHeight={800}
                        direction="vertical"
                        style={{
                            width: "100%",
                        }}
                    >
                        <Alert
                            message="Please Wait We Are Fetching the Bet Data"
                            type="info"
                        />
                    </Spin>
                </div>
            )}
        </>
    );
};

export default BetHistory;
