import {
    Table,
    Button,
    Row,
    notification,
    Modal,
    Input,
    Select,
    Col,
    Form,
} from "antd";
import React, { useEffect, useState } from "react";
import { DeleteOutlined, LeftOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import Apicall from "../../Helper/Apicall";
import { groupArray, subGroupArray } from "../../Data/GroupData";
import Loader from "../../Helper/Loader";
import { minimumBet } from "../../Data/TemplateData";

const Listtargetwebsite = ({ data,isStart }) => {
    const [api, contextHolder] = notification.useNotification();
    const [isModalOpen, setIsModalOpen] = useState(false); // state of Modal
    const [templates, setTemplates] = useState([]);
    const [confirmLoading, setConfirmLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [modalLoading, setModalLoading] = useState(false);
    const [groupData, setGroupData] = useState([]);
    const [subGroupData, setSubGroupData] = useState([]);
    const [oldTarget, setOldTarget] = useState({
        userName: "",
        templateCategoryCode: "",
        templateCategoryName: "",
        url: "",
        targetWebsiteId: "",
        password: "",
        pin: "",
        status: "",
        groupName: "",
        subGroupName: "",
    });

    // OnCancel of Modal
    const handleModalCancel = async () => {
        setIsModalOpen(false);
    };

    const editTarget = async (records) => {
        setIsModalOpen(true);
        setModalLoading(false);
        setOldTarget({
            ...oldTarget,
            userName: records.userName,
            templateCategoryCode: records.templateCategoryCode,
            templateCategoryName: records.templateCategoryName,
            targetWebsiteId: records.targetWebsiteId,
            url: records.url,
            status: records.status,
            groupName: records.groupName,
            subGroupName: records.subGroupName,
            isAutoLogin:true
        });
    };
  

    const getAllTemplate = async () => {
        let response = await Apicall("post", "getAllTemplate");
        if (response.success === 0) {
            api.error({ message: response.message });
        } else {
            setTemplates(response.data);
        }
    };

    const selectOneTemplate = (label, value) => {
        setOldTarget({
            ...oldTarget,
            templateCategoryCode: value.value,
            templateCategoryName: value.label,
        });
    };
    const getGroupDataApi = async () => {
        let data = await Apicall("post", "getAllGroups", {
            action: "groupData",
        });
        setGroupData(data.data);
        // setGroupData(groupArray);
    };
    const selectGroup = async (value, label) => {
        let data = await Apicall("post", "getAllGroups", {
            groupId: value,
            action: "subGroupData",
        });
        if (data.success === 1) {
            setSubGroupData(data.data);
        }
        console.log(subGroupData);
    };

    const selectSubGroup = (value, label) => {
        setOldTarget({ ...oldTarget, subGroupName: label.label });
    };

    const autoLoginUpdate = async(record)=>{
        record.isAutoLogin = true
        let response = await Apicall("post", "updateTargetWeb", record);
        if (response.success === 0) {
            throw new Error(response.message);
        } else {
            api.info({
                message: "Updated Website successfully",
                duration: 1,
            });
        }
    }

    const setStatus = (value) => {
        setOldTarget({ ...oldTarget, status: value });
    };

    const UpdateTargetWeb = async () => {
        // checking if anything has changed o not
        try {
            let payload = {
                userName: oldTarget.userName,
                password: oldTarget.password,
                templateCategoryCode: oldTarget.templateCategoryCode,
                targetWebsiteId: oldTarget.targetWebsiteId,
                templateCategoryName: oldTarget.templateCategoryName,
                pin: oldTarget.pin,
                url: oldTarget.url,
                status: oldTarget.status,
                minimumBet:oldTarget.minimalBet,
                nominalMul:oldTarget.nominalMul,
                groupName: oldTarget.groupName,
                subGroupName: oldTarget.subGroupName,
            };
            setModalLoading(true);
            let response = await Apicall("post", "updateTargetWeb", payload);
            if (response.success === 0) {
                throw new Error(response.message);
            } else {
                api.info({
                    message: "Updated Website successfully",
                    duration: 1,
                });

                setOldTarget({
                    userName: "",
                    templateCategoryCode: "",
                    templateCategoryName: "",
                    url: "",
                    targetWebsiteId: "",
                    password: "",
                    pin: "",
                });
                setIsModalOpen(false);
            }
        } catch (error) {
            api.error({ message: error.message });
            setIsModalOpen(false);
        }
    };

    useEffect(() => {
        data.map((item) => {
            item.isStart = isStart;
        });
    }, []);

    const columns = [
        {
            title: "Username",
            dataIndex: "userName",
            key: "oldTargetsiteId",
        },
        {
            title: "Template Category",
            dataIndex: "templateCategoryName",
            key: "oldTargetsiteId",
        },
        {
            title: "Url",
            dataIndex: "url",
            key: "oldTargetsiteId",
        },
        {
            title: "Group",
            dataIndex: "groupName",
            key: "oldTargetsiteId",
        },
        {
            title: "Sub Group Name",
            dataIndex: "subGroupName",
            key: "oldTargetsiteId",
        },
        {
            title: "Minimum Bet",
            dataIndex: "minimumBet",
            key: "oldTargetsiteId",
        },
        {
            title: "Nominal Mul",
            dataIndex: "nominalMul",
            key: "oldTargetsiteId",
        },
        {
            title: "Last Balance",
            dataIndex: "balance",
            key: "oldTargetsiteId",
        },

        {
            key: "operation",
            width: "220",
            render: (_, records) => {
                return (
                    <Button  disabled = {isStart} type ="primary" onClick={() => autoLoginUpdate(records)}>AUTOLOGIN</Button>
                );
            },
        },

        {
            title: "Action",
            key: "operation",
            width: "200",
            render: (_, records) => {
                return (
                    <Button onClick={() => editTarget(records)}>Edit</Button>
                );
            },
        },
    ];
    return (
        <div>
            {contextHolder}
            {loading ? (
                <Loader message={"Getting Website Details"} />
            ) : (
                <div style={{ backgroundColor: "#a3ffb4" }}>
                    <Row justify={"center"} align={"middle"}>
                        <Col span={8}>
                            <h4>LIST OF WEBSITE ASSOCIATED WITH SUBGROUP </h4>
                        </Col>
                    </Row>
                    <Table
                        columns={columns}
                        dataSource={data}
                        scroll={{
                            y: 500,
                        }}
                        defaultPageSize={100}
                    />
                    <Modal
                        title={`EDIT Target Website - ${oldTarget.url}`}
                        open={isModalOpen}
                        closeIcon={false}
                        width={1000}
                        onCancel={handleModalCancel}
                        onOk={UpdateTargetWeb}
                        confirmLoading={confirmLoading}
                        maskClosable={false}
                        bodyStyle={{ height: "60vh", overflow: "auto" ,top:0 }}
                    >
                        <div>
                            {contextHolder}
                            {modalLoading ? (
                                <Loader
                                    message={"Updating Website please waith"}
                                    width={30}
                                />
                            ) : (
                                <div>
                                    <Row className="input">
                                        Username :{" "}
                                        <Input
                                            placeholder="please provide userName"
                                            value={oldTarget.userName}
                                            onChange={(e) =>
                                                setOldTarget({
                                                    ...oldTarget,
                                                    userName: e.target.value,
                                                })
                                            }
                                        />
                                    </Row>
                                    <Row className="input">
                                        Password :{" "}
                                        <Input
                                            placeholder="Enter password if want ot changes password"
                                            value={oldTarget.password}
                                            onChange={(e) =>
                                                setOldTarget({
                                                    ...oldTarget,
                                                    password: e.target.value,
                                                })
                                            }
                                        />
                                    </Row>
                                    <Row>
                                        Select Group :{" "}
                                        <Select
                                            className="input"
                                            value={oldTarget.groupName}
                                            onChange={selectGroup}
                                            onClick={getGroupDataApi} // make api call to fetch data of Group
                                            style={{
                                                width: 1000,
                                            }}
                                            options={groupData}
                                        />
                                    </Row>
                                    <Row>
                                        Select Sub Group
                                        <Select
                                            className="input"
                                            value={oldTarget.subGroupName}
                                            onChange={selectSubGroup}
                                            onClick={getGroupDataApi} // decide latter to call api
                                            style={{
                                                width: 1000,
                                            }}
                                            options={subGroupData}
                                        />
                                    </Row>
                                    <Row>
                                        Minimul Bet
                                        <Select
                                            className="input"
                                            value={oldTarget?.minimalBet}
                                            onChange={(value, label) =>
                                                setOldTarget((pre) => ({
                                                    ...pre,
                                                    minimalBet: value,
                                                }))
                                            }
                                            style={{
                                                width: "100%",
                                            }}
                                            options={minimumBet}
                                        />
                                    </Row>
                                    <Row>
                                        Nominal Mul
                                        <Input
                                            className="input"
                                            value={oldTarget?.nominalMul}
                                            onChange={(e) =>
                                                setOldTarget((pre) => ({
                                                    ...pre,
                                                    nominalMul: Number(
                                                        e.target.value
                                                    ),
                                                }))
                                            }
                                            style={{
                                                width: "100%",
                                            }}
                                        />
                                    </Row>

                                    <Row>
                                        Select Template :{" "}
                                        <Select
                                            className="input"
                                            defaultValue={
                                                oldTarget.templateCategoryName
                                            }
                                            placeholder="select user Role"
                                            onChange={selectOneTemplate}
                                            onClick={getAllTemplate}
                                            style={{
                                                width: 700,
                                            }}
                                            options={templates}
                                        />
                                    </Row>
                                    <Row className="input">
                                        Pin :{" "}
                                        <Input
                                            placeholder="Enter pin if want to changes Pin"
                                            value={oldTarget.pin}
                                            onChange={(e) =>
                                                setOldTarget({
                                                    ...oldTarget,
                                                    pin: e.target.value,
                                                })
                                            }
                                        />
                                    </Row>
                                    <Row className="input">
                                        Url :{" "}
                                        <Input
                                            value={oldTarget.url}
                                            placeholder="please provide URL"
                                            onChange={(e) =>
                                                setOldTarget({
                                                    ...oldTarget,
                                                    url: e.target.value,
                                                })
                                            }
                                        />
                                    </Row>
                                    {/* <Row className="input">
                                        Serial No :{" "}
                                        <Input
                                            value={oldTarget.serialNo}
                                            placeholder="Add Serial No"
                                            onChange={(e) =>
                                                setOldTarget({
                                                    ...oldTarget,
                                                    serialNo: e.target.value,
                                                })
                                            }
                                        />
                                    </Row> */}
                                    <Row className="input">
                                        Status :{" "}
                                        <Select
                                            className="input"
                                            value={oldTarget.status}
                                            placeholder="Add Seri"
                                            onChange={setStatus}
                                            style={{
                                                width: 1000,
                                            }}
                                            options={[
                                                {
                                                    label: "Active",
                                                    value: "ACTIVE",
                                                },
                                                {
                                                    label: "Inactive",
                                                    value: "INACTIVE",
                                                },
                                            ]}
                                        />
                                    </Row>
                                </div>
                            )}
                        </div>
                    </Modal>
                </div>
            )}
        </div>
    );
};

export default Listtargetwebsite;
